import React, { useEffect, useContext, useRef } from 'react';
import './storeProducts.scss';

// Navigation
import { useNavigate } from 'react-router-dom';

// Context
import { Context } from '../../GlobalState/GlobalState';

// API services
import { fetchProducts } from '../../gateway/store-service-api';

// External components
import StoreProductsSkeleton from './StoreProductsSkeleton/StoreProductsSkeleton';
import ProductCard from '../ProductCard/ProductCard';

export default function StoreProducts() {
  const [context, dispatch] = useContext(Context);
  const { loadingProducts } = context;
  const isMountedRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    isMountedRef.current = true;
    if (context.store.id && (!context.products || context.products.length === 0)) {
      fetchStoreProducts(0);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [context.categoryId]);

  const fetchStoreProducts = async (page) => {
    dispatch({ type: 'SET_PRODUCTS_LOADING', loadingProducts: true });
    const { products, totalPages } = await fetchProducts(
      context.store.id,
      page,
      context.categoryId,
      context.companyId,
      context.searchTerm,
      context.orderBy,
      context.destinationCity?.cityCode,
    );
    if (isMountedRef.current) {
      dispatch({ type: 'ADD_PRODUCTS', products: [...context.products, ...products] });
      dispatch({ type: 'SET_TOTAL_PAGES', totalPages });
      dispatch({ type: 'SET_PAGE', page });
    }
  };

  const onSelectProduct = (product) => {
    dispatch({ type: 'SET_CURRENT_PRODUCT', currentProduct: product });
    navigate(`${product.id}`);
  };

  const isLastPage = () => 25 + context.page * 25 >= context.totalPages;
  return (
    <div className="StoreProducts">
      {loadingProducts || context.shippingCost === undefined ? (
        <StoreProductsSkeleton></StoreProductsSkeleton>
      ) : (
        context.products.length > 0 && (
          <div>
            <div className="storeProducts__grid">
              {context.products.map((product) => (
                <div className="productCard__container" key={product.id}>
                  <ProductCard
                    product={product}
                    handleProductClick={onSelectProduct}
                    isIncludingShippingPrice={context.store.isShippingPriceIncluded}
                  ></ProductCard>
                </div>
              ))}
            </div>
            {!isLastPage() && !loadingProducts && (
              <div className="moreProducts__buttonContainer">
                <button
                  className="button primary"
                  type="button"
                  onClick={() => fetchStoreProducts(context.page + 1)}
                >
                  Ver más productos
                </button>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}
